<template>
  <div>
    <div class="all-vars-switch">
      <h4>{{ $t('showModified') }}</h4>

      <v-switch
        v-model="showsOnlyModified"
        class="cy-inputs-switch mt-0 pt-4"
        color="secondary"
        @change="toggleModifiedOnly(Boolean($event))"/>
    </div>

    <section v-html="richDiff"/>
  </div>
</template>

<script>
import 'jsondiffpatch/lib/formatters/styles/html.css'

export default {
  name: 'CyStackFormsDiff',
  props: {
    vars: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    showsOnlyModified: true,
  }),
  computed: {
    richDiff () {
      const diffPatcher = require('jsondiffpatch')
      const htmlFormatter = require('jsondiffpatch/lib/formatters/html')

      const delta = diffPatcher.diff(this.vars?.old, this.vars?.new)

      const richDiff = htmlFormatter.format(delta, this.vars?.old)
      htmlFormatter.hideUnchanged()

      return richDiff
    },
  },
  methods: {
    toggleModifiedOnly (show) {
      const htmlFormatter = require('jsondiffpatch/lib/formatters/html')
      show
        ? htmlFormatter.hideUnchanged()
        : htmlFormatter.showUnchanged()
    },
  },
  i18n: {
    messages: {
      en: {
        after: 'After',
        before: 'Before',
        showModified: 'Show only modified values',
      },
      es: {
        after: 'Después',
        before: 'Antes',
        showModified: 'Mostrar solo valores modificados',
      },
      fr: {
        after: 'Après',
        before: 'Avant',
        showModified: 'Afficher uniquement les valeurs modifiées',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.all-vars-switch {
  display: flex;
  align-items: center;
  gap: 20px;
}
</style>
